<template>
  <div class="vg_wrapper">
    <add-header
        @submit="submit('mtqrForm')"
        @cancel="cancel"
        :statusShow="false"
    ></add-header>
    <el-form ref="mtqrForm" :model="mtqrForm" :rules="rules" label-width="120px" size="mini">
      <el-row>
        <el-col :md="8">
          <el-form-item label="物料编号" prop="mtrl_no">
            <el-tooltip class="item" effect="dark" content="仅限两位大写英文字母" placement="top-start">
              <el-input class="mtrlNo" :disabled="disabledFlagO" @input="mtqrForm.mtrl_no=helper.keepEnglish1(mtqrForm.mtrl_no)" v-model="mtqrForm.mtrl_no"  @change ="mtrlNoInput" maxlength="2" placeholder="例:CR" show-word-limit>
                <template slot="append">
                  <span class="vd_span">{{mtrlColor}}C</span>
                  <el-link type="primary" class="vg_cursor vd_mtrlCo" @click="mtrlImport">导入物料</el-link>
                  <el-link type="primary" class="vg_cursor" :disabled="!disabledFlagO" @click="cleanSechar">清空</el-link>
                </template>
              </el-input>   
            </el-tooltip>
          </el-form-item>
          <el-form-item label="潘通色号" prop="mtrl_color">
            <el-tooltip class="item" effect="dark" content="仅限四位数字" placement="top-start">
              <el-input class="mtrlcolor"  :disabled="disabledFlagO" @input="mtqrForm.mtrl_color=helper.keepTNum(mtqrForm.mtrl_color)" v-model="mtqrForm.mtrl_color" maxlength="4" @change="mtrlColorInput" show-word-limit placeholder="仅限四位数字">
                  <template slot="append">  <span v-if="mtqrForm.mtrl_color!=''">C</span></template>
              </el-input>
            </el-tooltip>
          </el-form-item>
          <el-form-item label="物料门幅" prop="mtrl_width">
            <el-input v-model="mtqrForm.mtrl_width" :disabled="disabledFlagO" maxlength="9" show-word-limit @change="mtqrForm.mtrl_width=helper.calcPrice(mtqrForm.mtrl_width,4,10000)" @input="mtqrForm.mtrl_width = helper.keepTNum1(mtqrForm.mtrl_width)" placeholder="请填写物料门幅">
               <template slot="append">米</template>
            </el-input>
          </el-form-item>
          <el-form-item label="物料毛高" prop="mtrl_thick">
            <el-input v-model="mtqrForm.mtrl_thick" :disabled="disabledFlagO" @input="mtqrForm.mtrl_thick = helper.keepTNum1(mtqrForm.mtrl_thick)" @change="mtqrForm.mtrl_thick=helper.retain1(mtqrForm.mtrl_thick,2,1000)" maxlength="6" show-word-limit placeholder="请填写物料毛高">
              <template slot="append">毫米</template>
            </el-input>
          </el-form-item>
          <el-form-item class="vd_form">
            <el-button type="success" size="small" :disabled="disabledFlagO" @click="synchronization">同步</el-button>
          </el-form-item>
        </el-col>
        <el-col :md="16">
          <el-row>
            <el-col :md="12">
              <el-form-item label="订单产品物料" prop="podr_prod_mtrl_id" v-if="!importOrLocalFlag">
                <el-select v-model="mtqrForm.podr_prod_mtrl_id" :disabled="!disabledFlagO" placeholder="请选择订单产品物料" clearable>
                  <el-option
                      v-for="item in podtList"
                      :key="item.podr_prod_mtrl_id"
                      :label="item.podtValue"
                      :value="item.podr_prod_mtrl_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> 
            <el-col :md="12">
              <el-form-item label="订单物料" prop="modr_mtrl_id" v-if="!importOrLocalFlag">
                <el-select v-model="mtqrForm.modr_mtrl_id" :disabled="!disabledFlagO" placeholder="请选择订单物料" clearable>
                  <el-option
                      v-for="item in modrList"
                      :key="item.modr_mtrl_id"
                      :label="item.modr_no"
                      :value="item.modr_mtrl_id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> 
          </el-row>
          <el-row>
            <el-col :md="12">
              <el-form-item label="物料名称" prop="mtrl_name">
                <el-input maxlength="30" v-model="mtqrForm.mtrl_name" :disabled="disabledFlag" placeholder="请填写物料名称" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="物料类型" prop="mtrl_type">
                <el-select v-model="mtqrForm.mtrl_type" :disabled="disabledFlag" placeholder="请选择物料类型" clearable>
                  <el-option
                      v-for="item in mtqrType"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12">
              <el-form-item label="物料单位" prop="mtrl_unit">
                <el-select v-model="mtqrForm.mtrl_unit" :disabled="disabledFlag" placeholder="请选择物料单位" clearable>
                  <el-option
                    v-for="item in mtrlTypeGroupOpt"
                    :key="item.id"
                    :label="item.param1"
                    :value="item.param1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="物料单价" prop="mtrl_price" v-if="importOrLocalFlag">
                <el-input v-model="mtqrForm.mtrl_price" @input="mtqrForm.mtrl_price = helper.keepTNum1(mtqrForm.mtrl_price)" @blur="mtqrForm.mtrl_price=helper.calcPrice(mtqrForm.mtrl_price,7,1000000)" maxlength="11" show-word-limit placeholder="请填写物料生产单价">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12"> 
              <el-form-item label="物料克重" prop="mtrl_weight" v-if="importOrLocalFlag">
                <el-input v-model="mtqrForm.mtrl_weight" @input="mtqrForm.mtrl_weight = helper.keepTNum1(mtqrForm.mtrl_weight)" @blur="mtqrForm.mtrl_weight=helper.retain1(mtqrForm.mtrl_weight,2,10000)" maxlength="7" show-word-limit placeholder="请填写物料克重">
                  <template slot="append">克</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="物料成分" prop="mtrl_comp" v-if="importOrLocalFlag">
                <el-select v-model="mtqrForm.mtrl_comp" placeholder="请选择物料成分" clearable>
                  <el-option
                      v-for="item in compType"
                      :key="item.id"
                      :label="item.param1"
                      :value="item.param1">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col> 
          </el-row>
          <el-row> 
            <el-col :md="12">  
              <el-form-item label="物料规格" prop="mtrl_spec" v-if="importOrLocalFlag">
                <el-input type="textarea" rows="1" v-model="mtqrForm.mtrl_spec" maxlength="50" show-word-limit placeholder="请填写物料规格"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="12">
              <el-form-item label="其他备注" prop="mtrl_remark" v-if="importOrLocalFlag">
                <el-input type="textarea" v-model="mtqrForm.mtrl_remark" :autosize="{ minRows: 2, maxRows: 6 }" maxlength="150" show-word-limit placeholder="请填写物料备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-divider></el-divider>      
      <el-row class="vg_mt_8 vg_mt_16">
        <el-col :md="2">
          <el-button size="small" type="warning" @click="addMatqrcd">添加</el-button>
        </el-col>
        <el-col :md="10">
           <el-table :data="mtqrForm.matqrcd_list" border >
            <el-table-column>
              <template slot="header">
                <span class="vg_deep_red">库位</span>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'matqrcd_list.' + scope.$index + '.mtqr_bin'"
                  :rules="[{ required: true }]"
                >
                  <el-select size="small" v-model="mtqrForm.matqrcd_list[scope.$index].mtqr_bin" placeholder="请选择库位" clearable>
                    <el-option
                      v-for="item in binList"
                      :key="item.id"
                      :label="item.param1"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column>
              <template slot="header">
                <span class="vg_deep_red">数量</span>
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'matqrcd_list.' + scope.$index + '.mtqr_num'"
                  :rules="[{ required: true }]"
                >
                  <el-input size="small" v-model="mtqrForm.matqrcd_list[scope.$index].mtqr_num" @input="mtqrForm.matqrcd_list[scope.$index].mtqr_num=helper.keepTNum1(mtqrForm.matqrcd_list[scope.$index].mtqr_num)" @change="mtqrForm.matqrcd_list[scope.$index].mtqr_num = helper.calcPrice(mtqrForm.matqrcd_list[scope.$index].mtqr_num,4,1000000)" maxlength="11" show-word-limit placeholder="请填写数量"></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
              <template slot-scope="scope">
                <el-button size="small" type="danger" @click="deleteMatqrcd(scope)">删除</el-button>
                <el-button size="small" v-if="scope.row.mtqr_num && scope.row.mtqr_bin" type="success" @click="copyMatqrcd(scope)">复制</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-dialog
        :title="title"
        :visible.sync="mtrlFlag"
        width="70%">
        <MtqrMtrlImport @falseMtrl="falseMtrl" @mtrlChanel="mtrlChanel"  @mtrlConfirm="mtrlConfirm" ref="MtqrMtrlImport"></MtqrMtrlImport>
      </el-dialog>
      <el-row class="vg_mt_16 vd_bortop">
        <el-col>
          <inputUser :isCookie="true" ref="userData"></inputUser>
        </el-col>
     </el-row>
    </el-form>
  </div> 
</template>

<script>
import {get,post} from "@api/request";
import {mtqrAPI} from "@api/modules/mtqr"; 
import {optnAPI} from "@api/modules/optn";
import {podtAPI} from "@api/modules/podt";
import {modrAPI} from "@api/modules/modr";
import addHeader from "@/views/component/addHeader";
import inputUser from "@/views/component/inputUser";
import MtqrMtrlImport from "@/views/StockManagement/MtqrManage/TabChild/Componet/MtqrMtrlImport.vue"

export default {
  name: "mtqrAddMain",
  components: {
    addHeader,
    inputUser,
    MtqrMtrlImport,
  },
  data() {
    return {
      rules:{
        mtqr_num:[
          { required: true, trigger: 'blur' , message: ' '},
        ],
        mtqr_bin:[
          { required: true, trigger: 'change' , message: ' '},
        ],
        mtrl_no: [
          { required: true, trigger: ["blur", "change"], message: " " },
        ],
        mtrl_name: [{ required: true, trigger: "blur", message: " " }],
        mtrl_type: [{ required: true, trigger: "blur", message: " " }],
        mtrl_unit: [{ required: true, trigger: "blur", message: " " }],
        mtrl_thick: [
          { required: true, trigger: ["blur", "change"], message: " " },
        ],
        mtrl_comp: [{ required: true, trigger: "blur", message: " " }],
        mtrl_stock_limit: [{ required: true, trigger: "blur", message: " " }],
        mtrl_price: [{ required: true, trigger: "blur", message: " " }],
        mtrl_width: [
          { required: true, trigger: ["blur", "change"], message: " " },
        ],
        mtrl_color: [
          { required: true, trigger: ["blur", "change"], message: " " },
        ],
        supp_id: [{ required: true, trigger: "blur", message: " " }],
        mtrl_weight: [{ required: true, trigger: ["blur", "change"], message: " " }],
      },
      mtqrForm:{
        mtrl_id:null,
        sour_mtrl_id:null,
        mtrl_no:null,
        mtrl_name:null,
        mtrl_unit:null,
        mtrl_type:null,
        mtqr_num:null,
        mtqr_bin:null,
        user_id: null,
        stff_id: null,
        dept_id: null,
        matqrcd_list:[{
          mtqr_bin:null,
          mtqr_num:null
        }],
        mtrl_weight: null,
        mtrl_spec :null,
        mtrl_remark: null,
        mtrl_comp:null,
        status:0,
        key:0,
      },
      binList:[],
      mtqrType:[
        {id:0,label:'原面料'},
        {id:1,label:'辅料'},
        {id:2,label:'包材'},
        {id:3,label:'加工面料'},
      ],
      mtrlType1:'原面料',
      mtrlType2:'辅料',
      mtrlType3:'包材',
      mtrlType4:'',
      mtrlTypeGroupOpt:[],
      suppType:[],
      mtrlFlag:false,
      sourFlag:false,
      title:'物料信息',
      compType:[],
      importOrLocalFlag:false,
      disabledFlag:true,
      disabledFlagO:false,
      mtrlColor:'',
      mtrlColorNum:'',
      podtList:[],
      modrList:[],
    }
  }, 
  watch: {
  },
  created() {
    this.initData()
  },
  methods:{
    change(val){
    },
    initData(){
      this.getMtqrType()
      this.getBinList()
      this.getCompType()
    },
    // 获取库位信息
    getBinList(){
      get(optnAPI.getAllContent,{perm_id : 10006})
      .then(res=>{
        if(res.data.code === 0){
          this.binList = res.data.data
        }
        else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg|| '';
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取成分
    getCompType(){
      get(optnAPI.getAllContent,{perm_id : 10008})
      .then(res=>{
        if(res.data.code === 0) {
          this.compType = res.data.data
        }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 获取订单产品物料
    getPodtList(ids){
      get(podtAPI.getPodtsByMtrlId,{mtrl_id:ids})
      .then(res=>{
        if(res.data.code === 0) {
          this.podtList = res.data.data.list
          for(let i=0;i<this.podtList.length;i++){
            this.podtList[i].podtValue = this.podtList[i].podr_no + '  ' + this.podtList[i].prod_no 
          }
          let temp = {};
          temp.podtValue = '暂无';
          temp.podr_prod_mtrl_id = this.podtList.length;
          this.podtList.push(temp)
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取订单物料
    getModrList(ids){
      get(modrAPI.getModrMtrlsByMtrlId,{mtrl_id:ids})
      .then(res=>{
        if(res.data.code === 0) {
          this.modrList = res.data.data.list
          let temp = {};
          temp.modr_no = '暂无';
          temp.modr_mtrl_id = this.modrList.length;
          this.modrList.push(temp)
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    // 获取单位
    getMtqrType(){
      get(optnAPI.getAllContent,{perm_id : 10005})
      .then(res=>{
        if(res.data.code === 0) {
          this.mtrlTypeGroupOpt = res.data.data
        }else{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      })
      .catch(res=>{
        let mg = res.data.msg;
        let tp = 'error';
        this.$message({message:mg,type:tp});
      })
    },
    //提交表单
    submit(formName){
      this.$confirm('确认提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.saveInfo()
          } else {
            this.$message({type:'warning',message:'请填入必填信息'})
            console.log('error submit!');
            return false;
          }
        });
      }).catch(()=>{})
    },
    // 保存
    saveInfo() {
      const staffForm =  this.$refs.userData.getData()
      let mtqrForm = {};
      mtqrForm = Object.assign(this.mtqrForm,staffForm)
      mtqrForm.mtrl_no=JSON.parse(JSON.stringify(mtqrForm.mtrl_no))+JSON.parse(JSON.stringify(mtqrForm.mtrl_color))+'C'+JSON.parse(JSON.stringify(this.mtrlColorNum));
      mtqrForm.mtrl_color = JSON.parse(JSON.stringify(mtqrForm.mtrl_color))+'C';
      if(this.importOrLocalFlag){
        mtqrForm.mtrl_id = null
        post(mtqrAPI.addMtrlToMtqr,mtqrForm)
        .then(res => {                                                    
          if(res.data.code === 0){
            this.$message({
              type:'success',
              message:'保存成功'
            })
            const permId = this.$route.query.perm_id
            this.jump(`/mtqr_list?perm_id=${permId}`)
            this.resetForm('mtqrForm')
            this.importOrLocalFlag=false;
            this.disabledFlag=true
            this.disabledFlagO=false
          }else if(res.data.code === 7){
            this.$message({
              type:'error',
              message:'库位中已有此物料'
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
            this.initData()
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        })
      }else{
        if(this.mtqrForm.podr_prod_mtrl_id+1 === this.podtList.length){
         this.mtqrForm.podr_prod_mtrl_id = null 
        }
        if(this.mtqrForm.modr_mtrl_id+1 === this.modrList.length){
          this.mtqrForm.modr_mtrl_id = null
        }
        post(mtqrAPI.addMtqr,mtqrForm)
        .then(res => {                                                    
          if(res.data.code === 0){
            this.$message({
              type:'success',
              message:'保存成功'
            })
            const permId = this.$route.query.perm_id
            this.jump(`/mtqr_list?perm_id=${permId}`)
            this.resetForm('mtqrForm')
            this.importOrLocalFlag=false;
            this.disabledFlag=true
            this.disabledFlagO=false
          }else if(res.data.code === 7){
            this.$message({
              type:'error',
              message:'库位中已有此物料'
            })
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
            this.initData()
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        })
      }
      
    },
    //取消
    cancel(){
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      }).then(() => {
        this.resetForm('mtqrForm')
        this.$router.go(-1)
        this.$message({
          type: 'info',
          message: '已取消新增!'
        });
      }).catch(()=>{
      })
    },
    // 清空内容
    cleanSechar(){
      this.$refs['mtqrForm'].resetFields()
      this.emptyContent()
      this.disabledFlagO=false;
      this.disabledFlag = true;
      this.importOrLocalFlag = false
    },
    // 同步查询
    synchronization(){
      let falg = false;
      if(this.mtqrForm.mtrl_no && this.mtqrForm.mtrl_color && this.mtqrForm.mtrl_width && this.mtqrForm.mtrl_thick){
        falg = true
      }else{
        return this.$message.warning('请补充完物料编号、潘通色号、物料毛高和物料门幅')
      }
      if(falg){
        let matqrcd_list = JSON.parse(JSON.stringify(this.mtqrForm.matqrcd_list))
        let params = {}
        params.mtrl_no=JSON.parse(JSON.stringify(this.mtqrForm.mtrl_no))+JSON.parse(JSON.stringify(this.mtqrForm.mtrl_color))+'C';
        params.mtrl_color = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_color))+'C';
        params.mtrl_width = this.mtqrForm.mtrl_width;
        params.mtrl_thick = this.mtqrForm.mtrl_thick;
        post(mtqrAPI.getMtrlByInfo,params)
        .then(res=>{
          if(res.data.code===0){
            this.mtqrForm = JSON.parse(JSON.stringify(res.data.data.form));
            this.mtqrForm.matqrcd_list = matqrcd_list
            this.mtrlColor = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_color.substr(0,this.mtqrForm.mtrl_color.indexOf('C'))))
            this.mtrlColorNum = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_no.substr(this.mtqrForm.mtrl_no.lastIndexOf('C')+1,this.mtqrForm.mtrl_no.length)))
            this.mtrlNoInput()
            this.mtrlColorInput(1)
            this.getPodtList(this.mtqrForm.mtrl_id)
            this.getModrList(this.mtqrForm.mtrl_id)
            this.disabledFlag = true;
            this.disabledFlagO = true;
            this.$message.success('同步成功')
          }else if(res.data.code===2){
            this.importOrLocalFlag = true
            this.disabledFlag = false
            this.disabledFlagO = true;
            this.$message.warning('物料库暂无此物料，请将内容填写完整')
          }else{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          }
        })
        .catch(res=>{
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message: mg, type: tp});
        })
      }
    },
    // 新增库位
    addMatqrcd(){
      let item = {};
      item.mtqr_num = null;
      item.mtqr_bin = null;
      this.mtqrForm.matqrcd_list.push(item)
    },
    // 删除库位
    deleteMatqrcd(val){
      if(this.mtqrForm.matqrcd_list.length<=1){
        this.$message.warning("至少保留一条")
      }else{
        this.mtqrForm.matqrcd_list.splice(val.$index,1)
      }
    },
    // 复制库位
    copyMatqrcd(val){
      let temp = JSON.parse(JSON.stringify(val.row))
      this.mtqrForm.matqrcd_list.splice(val.$index,0,temp)
    },
    // 物料导入
    mtrlImport(){
      this.mtrlFlag = true;
    },
    // 物料导入取消
    mtrlChanel(){
      this.mtrlFlag = false;
    },
    falseMtrl(){this.mtrlChanel()},
    // 物料导入确认
    mtrlConfirm(val){
      let matqrcd_list = JSON.parse(JSON.stringify(this.mtqrForm.matqrcd_list))
      this.mtqrForm = JSON.parse(JSON.stringify(val));
      this.mtqrForm.mtrl_price = this.helper.haveFour(this.mtqrForm.mtrl_price)
      this.mtqrForm.mtrl_width = this.helper.haveFour(this.mtqrForm.mtrl_width)
      this.mtqrForm.mtrl_thick = this.helper.haveFour(this.mtqrForm.mtrl_thick)
      this.mtqrForm.matqrcd_list = matqrcd_list;
      this.mtrlColor = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_color.substr(0,this.mtqrForm.mtrl_color.indexOf('C'))))
      this.mtrlColorNum = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_no.substr(this.mtqrForm.mtrl_no.lastIndexOf('C')+1,this.mtqrForm.mtrl_no.length)))
      this.mtrlNoInput()
      this.mtrlColorInput(1)
      this.getPodtList(this.mtqrForm.mtrl_id)
      this.getModrList(this.mtqrForm.mtrl_id)
      this.disabledFlagO = true
      this.disabledFlag = true
      this.importOrLocalFlag = false
      this.mtrlFlag = false
    },
    mtrlNoInput(){
      this.mtqrForm.mtrl_no =  this.helper.keepEnglish(this.mtqrForm.mtrl_no).toUpperCase()
      this.mtqrForm.mtrl_no =  this.mtqrForm.mtrl_no.substr(0,2)
    },
    mtrlColorInput(no){
      this.mtqrForm.mtrl_color = this.helper.keepTNum(this.mtqrForm.mtrl_color)
      if(no !== 1){
        this.mtrlColor = JSON.parse(JSON.stringify(this.mtqrForm.mtrl_color))
      }
      // if(this.mtqrForm.mtrl_color.indexOf('C') !== 0){
      // console.log('this.mtqrForm.mtrl_color',this.mtqrForm.mtrl_color);
      //   this.mtqrForm.mtrl_color = this.mtqrForm.mtrl_color.substr(0, this.mtqrForm.mtrl_color.indexOf('C'));
      // }
    },
    // 清空内容
    emptyContent(){
      this.mtqrForm.mtrl_price = null
      this.mtqrForm.mtrl_weight = null
      this.mtqrForm.mtrl_spec = null
      this.mtqrForm.mtrl_remark = null
      this.mtqrForm.mtrl_comp = null
    },
    //表单重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.mtqrForm.matqrcd_list = [{
        mtqr_bin:null,
        mtqr_num:null
      }]
      this.mtrlColorNum = '';
      this.mtrlColor = '';
      this.emptyContent()
      this.disabledFlag=true;
      this.disabledFlagO=false
      this.importOrLocalFlag = false
    },
  }
}
</script>

<style scoped lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader ::v-deep .el-upload--text {
  width: 136px;
  height: 136px;
}
.avatar-uploader .el-upload:hover {
  border-color: $color-primary;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: $color-uploader-icon;
  width: 136px;
  height: 136px;
  line-height: 136px;
  text-align: center;
}
.avatar {
  width: 136px;
  height: 136px;
  display: block;
}
.vd_bortop {
  border-top: #DCDFE6 dashed 1px;
  padding-top: 20px;
}
::v-deep .el-link.el-link--primary{
  height: 100%;
  border-right: 1px solid #b9b9b9;
  border-left: 1px solid #b9b9b9;
  padding:0 10px;
}
::v-deep .el-link.el-link--primary:last-child{
  border-left: none;
  border-right: none;
}
.vd_span{
  margin-right: 10px;
}
.vd_form{
  display: flex;
  justify-content: right;
}
</style>